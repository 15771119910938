import React from "react";
import Theme from "./Theme";
import Styled from "styled-components";
import ImgLogo from "./assets/logo.svg";
import ImgScan from "./assets/scan.png";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        
    }

    componentWillUnmount() {

    }

    render() {
        return(
            <Container>
                <Header>
                    <Logo src={ImgLogo} />
                </Header>
                <FlexContent>
                    <ButtonView>
                        <Title>Testen 2.0 - Corona-Antigen Test mit Zertifikat</Title>
                        <Banner>
                            <BannerIcon src={ImgScan} />
                            <BannerText>Um Zertfikate in den Varianten 1, 2 oder 3 auszustellen, scannen Sie den QR-Code auf dem Tray.<br />Nur für Variante 3 ist vorher eine Unternehmensregistrierung erforderlich</BannerText>
                        </Banner>
                        <HighlightView>
                            <Text size="16px" width="300" margin="0 0 10px 0">Zum Ausstellen von Zertifikaten nach § 22a IfSG ist die Registrierung Ihres Unternehmens erforderlich.</Text>
                            <Button onClick={this.clickNew.bind(this)}>
                                <ButtonText>Unternehmen registrieren</ButtonText>
                                <ButtonIcon viewBox="0 0 24 24">
                                    <path d="m6.49 20.13 1.77 1.77 9.9-9.9-9.9-9.9-1.77 1.77L14.62 12l-8.13 8.13Z" />
                                </ButtonIcon>
                            </Button>
                            <Text size="16px" width="300" margin="20px 0 10px 0">Sie besitzen bereits eine Unternehmens-ID und möchten auf Ihre Statistik/Protokolle zugreifen.</Text>
                            <Button onClick={this.clickNew.bind(this)}>
                                <ButtonText>Login</ButtonText>
                                <ButtonIcon viewBox="0 0 24 24">
                                    <path d="m6.49 20.13 1.77 1.77 9.9-9.9-9.9-9.9-1.77 1.77L14.62 12l-8.13 8.13Z" />
                                </ButtonIcon>
                            </Button>
                            <Divider />
                            <Text size="16px" width="300" margin="20px 0 10px 0">Sie nutzen DigitalKeyCode 1.0 oder sind Nutzer der DigitalKeyCode-App.</Text>
                            <ButtonOutline onClick={this.clickOld.bind(this)}>
                                <ButtonOutlineText>Weiter</ButtonOutlineText>
                                <ButtonOutlineIcon viewBox="0 0 24 24">
                                    <path d="m6.49 20.13 1.77 1.77 9.9-9.9-9.9-9.9-1.77 1.77L14.62 12l-8.13 8.13Z" />
                                </ButtonOutlineIcon>
                            </ButtonOutline>
                        </HighlightView>
                    </ButtonView>
                    <InfoView>
                        <InfoBlock>
                            <InfoItem>
                                <InfoNumber>1</InfoNumber>
                                <InfoMore>
                                    <InfoHead>Bescheinigung freiwilliger Selbsttests (z. B. gemäß ArbSchVO)</InfoHead>
                                    <InfoTitle>Selbsttest-Zertifikat</InfoTitle>
                                    <InfoText>Das Zertifikat kann überall verwendet werden. (Ausnahme: Einrichtungen, die in § 28b IfSG genannt sind - dafür Nr. 2 oder 3 verwenden)</InfoText>
                                </InfoMore>
                            </InfoItem>
                            <InfoItem>
                                <InfoNumber>2</InfoNumber>
                                <InfoMore>
                                    <InfoHead>Krankenhäuser & Medizinische Einrichtungen mit Testpflicht</InfoHead>
                                    <InfoTitle>Beaufsichtigter Selbsttest mit Testpflicht nach § 28b IfSG*</InfoTitle>
                                    <InfoText>Der Test erfolgt unter Aufsicht eines Mitarbeiters der Einrichtung. Die Gültigkeit des Zertifikates beträgt 24 Stunden für die jeweilige Einrichtung und kann ansonsten wie Nr. 1 verwendet werden.</InfoText>
                                </InfoMore>
                            </InfoItem>
                            <InfoItem>
                                <InfoNumber>3</InfoNumber>
                                <InfoMore>
                                    <InfoHead>Europaweites gültiges Zertifikat für Betriebe / Unternehmen</InfoHead>
                                    <InfoTitle>Test im Rahmen des betrieblichen Arbeitsschutzes*</InfoTitle>
                                    <InfoText>Der Test erfolgt durch Personal des Betriebes. Das Zertifikat gilt auch in Einrichtungen die unter § 28b IfSG fallen.</InfoText>
                                </InfoMore>
                            </InfoItem>
                        </InfoBlock>
                    </InfoView>
                </FlexContent>
            </Container>
        );
    }

    clickNew() {
        let param = new URLSearchParams(window.location.search);
        let id = param.get("sid");
        id = id != null && id.length >= 6 ? ("&sid=" + id) : "";
        window.location.href = window.location.origin + '/login_fbauth/alternative.html?service=rapidoc&title=true&mode=mail&v=2' + id;
    }

    clickOld() {
        window.location.href = "https://app.digitalkeycode.com/webui/login-dhc/";
    }

}

const Container = Styled.div`
    width: 100vw;
    min-height: 100vh;
`;
const Header = Styled.div`
    box-shadow: 0px 5px 15px rgb(0 0 0 / 8%);
    width: 100vw;
    padding: 15px;
    background-color: ${Theme.hightlight};
    z-index: 100;
`;
const Logo = Styled.img`
    height: 35px;
`;
const Banner = Styled.div`
    max-width: fit-content;
    padding: 10px;
    display: flex;
    flex-direction: row;
    border: ${Theme.red + " 2px solid"};
    border-radius: 10px;
    background-color: ${Theme.red + "1A"};
    margin: 20px 0 20px 0;
    align-items: center;
`;
const BannerIcon = Styled.img`
    width: 30px;
    object-fit: contain;
`;
const BannerText = Styled.p`
    flex: 1;
    font-size: 18px;
    font-weight: 400;
    padding: 0 0 0 10px;
`;
const FlexContent = Styled.div`
    width: 100vw;
    min-height: calc(100vh - 69px);
    display: flex;
    flex-direction: row;
    z-index: 10;
    padding: 20px;
    @media all and (max-width: 700px) {
        display: block;
    }
`;
const ButtonView = Styled.div`
    width: 600px;
    padding: 0 20px 0 0;
    border-right: 2px solid #00000033;
    @media all and (max-width: 1200px) {
        width: 500px;
    }
    @media all and (max-width: 1000px) {
        width: 400px;
    }
    @media all and (max-width: 900px) {
        width: 300px;
    }
    @media all and (max-width: 700px) {
        width: 100%;
        border-right: unset;
        padding: 0 0 20px 0;
    }
`;
const InfoView = Styled.div`
    flex: 1;
    height: 100%;
    padding: 20px;
`;
const Title = Styled.p`
    font-weight: 600;
    font-size: 24px;
`;
const Subtitle = Styled.p`
    font-weight: 400;
    font-size: 18px;
`;
const Text = Styled.p`
    font-size: ${(props) => props.size ?? "18px"};
    font-weight: ${(props) => props.weight ?? "400"};
    color: #000;
    margin: ${(props) => props.margin ?? "0"};
`;
const Button = Styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 15px 25px;
    background-color: ${Theme.blue};
    border-radius: 5px;
    &:hover {
        scale: 1.01;
        background-color: ${Theme.blue + "E6"};
    }
    &:hover > svg {
        transform: translate(10px);
    }
    &:active {
        scale: 0.99;
    }
`;
const ButtonText = Styled.p`
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    flex: 1;
`;
const ButtonIcon = Styled.svg`
    fill: #FFFFFF;
    height: 20px;
    width: 20px;
    transition: transform .2s ease;
`;
const ButtonOutline = Styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 15px 25px;
    border: ${Theme.blue + " 1px solid"};
    border-radius: 5px;
    background-color: ${Theme.background};
    &:hover {
        scale: 1.01;
        background-color: ${Theme.blue + "0D"};
    }
    &:hover > svg {
        transform: translate(10px);
    }
    &:active {
        scale: 0.99;
    }
`;
const ButtonOutlineText = Styled.p`
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: ${Theme.blue};
    flex: 1;
`;
const ButtonOutlineIcon = Styled.svg`
    fill: ${Theme.blue};
    height: 20px;
    width: 20px;
    transition: transform .2s ease;
`;
const Divider = Styled.div`
    height: 1px;
    width: 100%;
    margin: 0 auto;
    background-color: #00000033;
    margin: 20px 0;
`;
const HighlightView = Styled.div`
    padding: 20px;
    border-radius: 10px;
    margin: 20px 0 0 0;
    background-color: ${Theme.hightlight};
    border: #f0f0f0 1px solid;
`;
const InfoBlock = Styled.div`
    width: 800px;
    margin: auto;
    @media all and (max-width: 1480px) {
        width: 100%;
    }
`;
const InfoItem = Styled.div`
    display: flex;
    &:not(:first-child) {
        margin: 20px 0 0 0;
    }
`;
const InfoNumber = Styled.div`
    font-size: 30px;
    font-weight: 900;
    height: 60px;
    width: 60px;
    text-align: center;
    border-radius: 100px;
    background-color: ${Theme.blue};
    line-height: 60px;
    color: #FFFFFF;
    z-index: 20px;
`;
const InfoMore = Styled.div`
    flex: 1;
    padding: 0 0 0 10px;
`;
const InfoHead = Styled.div`
    background-color: ${Theme.blue + "80"};
    padding: 0 20px 0 30px;
    margin: 5px 0 15px -30px;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: #FFFFFF;
    border-radius: 0 30px 30px 0;
    z-index: 15px;
`;
const InfoTitle = Styled.div`
    font-size: 18px;
    font-weight: 700;
    color: ${Theme.red};
`;
const InfoText = Styled.div`
    font-size: 16px;
    font-weight: 500;
`;

export default App;